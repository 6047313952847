import React from 'react'
import '../styles/about.css'

import kerala from '../images/keralaVector.png'
import rectangle from '../images/Group 41.png'
import Layout from '../components/layout'
import line from '../images/Group.png'
import Contact from '../components/contact'
import Contact2 from '../components/contact2'

const About2 = ({title}) => {

  return (

    <Layout>

        <div>
            <img className='w-full' src={rectangle} alt='background' />
        </div>
        <div className='ImageAndTitle flex items-center justify-center font-hind xl:-mt-36 md:-mt-32 -mt-24'>
            <img className='keralaImg' src={kerala} alt='' />
            <div>
                <h1 className='aboutTitle text-primary'>Kerala’s First Ever</h1>
                <h1 className='aboutSubTitle text-primary'>Exclusive Showroom for Blue Star<br/> Commercial Refrigeration Systems</h1>
            </div>
        </div>
    
        <div className='about flex justify-center items-center font-hind' id='about2'>
            <div className='aboutSection text-black'>
            
                {/* <div className='ImageAndTitle flex items-center justify-center'>
                    <img className='keralaImg' src={kerala} alt='' />
                    <div> */}
                        <h1 className='aboutTitle text-primary text-center pt-6'>About Us</h1>
                        {/* <h1 className='aboutSubTitle text-primary'>Exclusive Showroom for Blue Star<br/> Commercial Refrigeration Systems</h1>
                    </div>
                </div> */}
                <h1 className='aboutDescription'>
                    Freezeon aims to provide the best commercial refrigeration products and excellent 
                    after sales service to all our customers. With over three decades of experience in 
                    HVAC and Refrigeration industry, our vast understanding and expertise in the industry 
                    makes us your best choice for commercial refrigeration systems.
                </h1>
            </div>

        </div>

        <div>
            <h1 className='aboutTitle text-primary text-center pt-12'>Our Journey</h1>
            <div>
                

<section>
    <div className=" text-primary py-8">
    <div className="container mx-auto flex justify-center items-start md:flex-row my-12 md:my-2  font-montserrat">
      
      
      <div className="ml-0 md:ml-12 flex sm:w-full  lg:w-2/3 sticky">
        <div className="container mx-auto h-full">
          <div className="relative wrap overflow-hidden px-10 h-full">
            
            <div className="hidden sm:block borderLine absolute"
              style={{right: "50%", border: "4px solid #61CBED"}}></div>
            <div className="hidden sm:block borderLine absolute"
              style={{left: "50%", border: "4px solid #61CBED"}}></div>
            
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-12"></div>
              <div className="rightDiv order-1 px-1 text-left">
                <h4 className="yearTextRight mb-0">1998</h4>
                <img className='dotLine1' src={line} alt='line' />
                <p className="thatDayText1 text-black">
                    Founded Cool Tech Refrigeration and Air Conditioning Service Center in Manjeri
                </p>
              </div>
            </div>
            
            <div className="hidden sm:block borderLine2 absolute  "
              style={{right: "50%", border: "4px solid #2CA5C1"}}></div>
            <div className="hidden sm:block borderLine2 absolute  "
              style={{left: "50%", border: "4px solid #2CA5C1"}}></div>

            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-12"></div>
              <div className="leftDiv order-1 px-1 text-right">
                <h4 className="yearTextRigh2 mb-0">2002</h4>
                <img className='dotLine2 origin-center rotate-180' src={line} alt='line' />
                <p className="thatDayText2">
                    Started Sales and Service off Major Air Conditioning Brands in Manjeri
                </p>
              </div>
            </div>

            <div className="hidden sm:block borderLine3 absolute"
              style={{right: "50%", border: "4px solid #076282"}}></div>
            <div className="hidden sm:block borderLine3 absolute"
              style={{left: "50%", border: "4px solid #076282"}}></div>
            
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-12"></div>
              <div className="rightDiv order-1 px-1 text-left">
                <h4 className="yearTextRight mb-0">2007</h4>
                <img className='dotLine1' src={line} alt='line' />
                <p className="thatDayText1 text-black">
                    Renamed to Key Bees Technology - Opened Kerala’s First Exclusive Multi Brand Air Conditioner Showroom
                </p>
              </div>
            </div>
            
            <div className="hidden sm:block borderLine4 absolute  "
              style={{right: "50%", border: "4px solid #1C417A"}}></div>
            <div className="hidden sm:block borderLine4 absolute  "
              style={{left: "50%", border: "4px solid #1C417A"}}></div>

            <div className="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
              <div className="order-1 w-12"></div>
              <div className="leftDiv order-1 px-1 text-right">
                <h4 className="yearTextRigh2 mb-0">2014</h4>
                <img className='dotLine2 origin-center rotate-180' src={line} alt='line' />
                <p className="thatDayText2">
                    Daikin Solution Plaza -  Authorised Daikin Dealer and Leading HVAC Solution Prooviders in the region
                </p>
              </div>
            </div>

            <div className="hidden sm:block borderLine absolute"
              style={{right: "50%", border: "4px solid #1C417A"}}></div>
            <div className="hidden sm:block borderLine absolute"
              style={{left: "50%", border: "4px solid #1C417A"}}></div>
            
            <div className="mb-8 flex justify-between items-center w-full right-timeline">
              <div className="order-1 w-12"></div>
              <div className="rightDiv order-1 px-1 text-left">
                <h4 className="yearTextRight mb-0">2022</h4>
                <img className='dotLine1' src={line} alt='line' />
                <p className="thatDayText1 text-black">
                    Freezeon - Kerala’s  First Ever Exclusive Showroom for Blue Star Commercial Refrigeration Systems
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
            </div>
            <Contact/>
            <Contact2/>
        </div>
    </Layout>
    
  )
}

export default About2
